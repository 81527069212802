import React from 'react';
import { useHistory } from 'react-router-dom';
import { featureEnabled } from 'feature-toggles!sofe';
import { find, get, keys, map, partial } from 'lodash';
import { always } from 'kremling';
import { CpButton, CpLoader, CpEmptyState } from 'canopy-styleguide!sofe';
import ColumnHeader from 'src/common/components/column_header/column-header.component';
import { views } from 'src/common/components/column_header/columns.helpers';
import { columnKeys } from '../invoices.helper';
import { getInvoiceFilter, getRecurrenceFilter } from 'src/resources/invoices.resources';

import styles from './invoice-table.styles.css';

const InvoiceTable = ({
  invoiceType,
  columns,
  invoices,
  selectedInvoices,
  allowSelection,
  allowFilterSort,
  isFiltered,
  showLoadMoreButton,
  loadingMore,
  loadNextPage,
  selectAll,
  checkboxChanged,
  hideSpacer,
  clientId,
  onShowOverlay,
  onShowAddPayment,
  integrationInfo,
}) => {
  const includeSelectColumn = invoiceType !== 'recurring' && allowSelection;
  const history = useHistory();

  const renderHeader = () => {
    return (
      <tr className="cps-card-table__thin">
        {includeSelectColumn && (
          <th className="cps-card-table__select-column">
            <label className="cps-checkbox">
              <input
                type="checkbox"
                onChange={selectAll}
                checked={invoices.length > 0 && selectedInvoices.length === invoices.length}
              />
              <span />
            </label>
          </th>
        )}
        {map(columns, (column, columnKey) => {
          return (
            <ColumnHeader
              key={columnKey}
              allowFilterSort={allowFilterSort && ![columnKeys.INVOICE_DESCRIPTION, columnKeys.QBO].includes(columnKey)}
              column={column}
              getDynamicFilters={
                invoiceType === 'recurring'
                  ? getRecurrenceFilter
                  : (filterName, filters, sortFilterList, searchValue) =>
                      getInvoiceFilter(filterName, filters, invoiceType === 'archived', searchValue)
              }
              maxWidth={140}
            />
          );
        })}
      </tr>
    );
  };

  const renderBody = () => {
    return (
      invoices &&
      invoices.length > 0 &&
      invoices.map((invoice, index) => (
        <tr key={index} className="+hover" style={{ cursor: 'default' }}>
          {invoiceType !== views.recurring && allowSelection && (
            <td className="cps-card-table__select-column">
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  onChange={partial(checkboxChanged, invoice)}
                  checked={!!find(selectedInvoices, selectedInvoice => invoice.id === selectedInvoice.id)}
                />
                <span />
              </label>
            </td>
          )}
          {map(columns, (column, columnKey) => {
            return (
              <td
                key={columnKey}
                style={{
                  minWidth:
                    columnKey === columnKeys.CLIENT
                      ? '14.2rem'
                      : columnKey === columnKeys.INVOICE_DESCRIPTION
                      ? '18.4rem'
                      : '',
                  maxWidth: column.sortParam !== 'status' ? '120px' : 'inherit',
                }}
                className={always(styles.invoiceCell)
                  .always('cps-ellipsis')
                  .maybe('cp-pl-12', !column.first)
                  .maybe('cp-pl-32', column.first)
                  .maybe('cp-pr-12', true)}>
                {renderCell(columnKey, column, invoice) || <span>&mdash;</span>}
              </td>
            );
          })}
        </tr>
      ))
    );
  };

  const renderCell = (columnKey, columnAttrs, invoice) => {
    if (
      [
        columnKeys.INVOICE_NUMBER,
        columnKeys.CLIENT,
        columnKeys.INVOICE_STATUS,
        columnKeys.RECURRING_DESCRIPTION,
        columnKeys.RECURRING_FREQUENCY,
      ].includes(columnKey)
    ) {
      return columnAttrs.displayComponent(invoice, invoiceType, clientId, onShowOverlay, history);
    } else if (columnKey === columnKeys.INVOICE_DESCRIPTION) {
      return <columnAttrs.displayComponent invoice={invoice} />;
    } else if (columnKey === columnKeys.ADD_PAYMENT) {
      return columnAttrs.displayComponent(invoice, invoiceType, clientId, onShowAddPayment);
    } else if (
      (columnKey === columnKeys.INVOICE_DUEDATE && invoice.status === 'draft') ||
      (columnKey === columnKeys.NEXT_OCCURRENCE && !invoice.started)
    ) {
      // do not show due dates for drafts or next occurrence for inactive recurrences
    } else if (columnKey === columnKeys.QBO) {
      return <columnAttrs.displayComponent invoice={invoice} integrationInfo={integrationInfo} />;
    } else {
      return columnAttrs.displayComponent(get(invoice, columnKey));
    }
  };

  const renderFooter = () => {
    return (
      invoices &&
      invoices.length > 0 &&
      showLoadMoreButton &&
      (featureEnabled('toggle_gs_invoice_description') ? (
        <div
          className="flex items-center justify-center"
          style={{ height: '48px', borderTop: '1px solid var(--cp-color-app-border)' }}>
          {loadingMore ? (
            <div style={{ display: 'initial' }}>
              <CpLoader />
            </div>
          ) : (
            <CpButton btnType="flat" onClick={loadNextPage}>
              Load More
            </CpButton>
          )}
        </div>
      ) : (
        <tr key="loadMoreRow">
          <td colSpan={keys(columns).length + 1} style={{ textAlign: 'center', height: '48px' }}>
            {loadingMore ? (
              <div style={{ display: 'initial' }}>
                <CpLoader />
              </div>
            ) : (
              <CpButton btnType="flat" onClick={loadNextPage}>
                Load More
              </CpButton>
            )}
          </td>
        </tr>
      ))
    );
  };

  return (
    <div className="cps-animate-fade">
      <div
        className={featureEnabled('toggle_gs_invoice_description') && includeSelectColumn ? styles.invoiceTable : ''}
        style={{ overflowX: featureEnabled('toggle_gs_invoice_description') ? 'auto' : '' }}>
        <table>
          <thead>
            {renderHeader()}
            {!hideSpacer && (
              <tr className="+thin">
                <th colSpan={keys(columns).length + 1} />
              </tr>
            )}
          </thead>
          <tbody>
            {renderBody()} {!featureEnabled('toggle_gs_invoice_description') && renderFooter()}
          </tbody>
        </table>
      </div>
      {featureEnabled('toggle_gs_invoice_description') && renderFooter()}
      {(!invoices || invoices.length === 0) && isFiltered && (
        <CpEmptyState
          img={invoiceType !== 'recurring' ? 'es_billing_invoice' : 'es_billing_recurring'}
          text={invoiceType !== 'recurring' ? 'No invoices found' : 'No recurring invoices found'}
          subText="Update the filters to view more invoices"
          className="cp-mt-16 cp-mb-16"
        />
      )}
    </div>
  );
};

export default InvoiceTable;
