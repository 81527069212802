// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-styles__tab--qAI0C {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  border-left: solid 2px white;
}

.navigation-styles__tab--qAI0C:hover {
  background-color: #f3f3f3;
}

.navigation-styles__selectedTab--ZpL5z {
  border-color: var(--cps-color-primary);
}

.navigation-styles__unselectedTab--ydi3X:hover {
  border-color: #f3f3f3;
}

.navigation-styles__leftNav--WuRYR {
  height: auto;
  width: 176px;
  overflow: hidden;
}

.navigation-styles__subTab--REcIb {
  padding: 8px 24px;
  cursor: pointer;
  color: var(--cps-color-cool-gray);
}
`, "",{"version":3,"sources":["webpack://./src/navigation/navigation.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iCAAiC;AACnC","sourcesContent":[".tab {\n  display: flex;\n  align-items: center;\n  padding: 10px 16px;\n  cursor: pointer;\n  border-left: solid 2px white;\n}\n\n.tab:hover {\n  background-color: #f3f3f3;\n}\n\n.selectedTab {\n  border-color: var(--cps-color-primary);\n}\n\n.unselectedTab:hover {\n  border-color: #f3f3f3;\n}\n\n.leftNav {\n  height: auto;\n  width: 176px;\n  overflow: hidden;\n}\n\n.subTab {\n  padding: 8px 24px;\n  cursor: pointer;\n  color: var(--cps-color-cool-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `navigation-styles__tab--qAI0C`,
	"selectedTab": `navigation-styles__selectedTab--ZpL5z`,
	"unselectedTab": `navigation-styles__unselectedTab--ydi3X`,
	"leftNav": `navigation-styles__leftNav--WuRYR`,
	"subTab": `navigation-styles__subTab--REcIb`
};
export default ___CSS_LOADER_EXPORT___;
