import React, { lazy, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CpDropdown, CpLoader, CpToggle, CpTextarea } from 'canopy-styleguide!sofe';
import { useHasAccess } from 'cp-client-auth!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { RecurringPayment } from './form-components/recurring-payment.component';
import { RecurringHistory } from './recurring-history.component';
import { InputGroup } from 'src/common/components/input_group/input-group.component';
import { calculateSubTotal } from './invoice-builder.helper';
import { AvailableCredits } from './available-credits.component';
import { decimalNumberFilter } from 'src/billing-helpers';
import { invoiceCalc, invoiceTypes, toCurrency, toNumber } from 'src/invoices/invoices.helper';
import { useCanopyPayments } from 'src/payments/payments.helper';

import styles from './invoice-builder.styles.css';

const CpRichTextEditor = lazy(() =>
  import('rich-text-ui!sofe')
    .then(module => module.getRichTextEditor())
    .then(rte => rte)
);

export const InvoiceOptions = () => {
  const { mode, invoice, recurrence, availableCredits, invoiceType, options, actions, errors, adyenPaymentDetails } =
    useContext(InvoiceContext);
  const { hasCanopyPayments, hasAdyen, teamCanKeyInCards, amexEnabled } = useCanopyPayments();
  const canCreateEditInvoice = useHasAccess('billing_invoices_create_edit');
  const canViewPayment = useHasAccess('billing_payments');
  const canCreatePayment = useHasAccess('billing_payments_create_edit');
  const [highlightField, setHighlightField] = useState(null);
  const [internalDiscount, setInternalDiscount] = useState(invoice.singleLineDiscount);
  const clientNote = useRef(null);
  const termsAndConditions = useRef(null);

  const lineItemSubTotal = useMemo(() => {
    const timeEntryTotal = calculateSubTotal(invoice.lineItems.fromTime);
    const lineItemTotal = calculateSubTotal(invoice.lineItems.standard);

    return {
      sumTax: timeEntryTotal.sumTax + lineItemTotal.sumTax,
      sumTotal: timeEntryTotal.sumTotal + lineItemTotal.sumTotal,
    };
  }, [invoice.lineItems.fromTime, invoice.lineItems.standard]);

  const disabled = (invoiceType === invoiceTypes.recurring && recurrence.status === 'Ended') || !canCreateEditInvoice;
  const hasBalance = useMemo(() => invoiceCalc.getBalanceDue(invoice) > 0, [invoice]);

  useEffect(() => {
    if (!highlightField) return;

    highlightField.select();
    setHighlightField(null);
  }, [highlightField]);

  useEffect(() => {
    const filteredDiscount = decimalNumberFilter(invoice.singleLineDiscount.toString(), 2, false, false);
    setInternalDiscount(
      invoice.singleLineDiscountIsPercent ? toNumber(filteredDiscount) : toCurrency(filteredDiscount, false)
    );
  }, [invoice.singleLineDiscount, invoice.singleLineDiscountIsPercent]);

  const updateSingleLineDiscount = (discount, discountIsPercent, proRate = true) => {
    if (proRate) {
      const total =
        calculateSubTotal(invoice.lineItems.fromTime, true).sumTotal +
        calculateSubTotal(invoice.lineItems.standard, true).sumTotal;

      if (discountIsPercent && discount > 100) {
        discount = 100;
      } else if (!discountIsPercent && discount > total) {
        discount = total;
      }

      invoice.lineItems.standard?.forEach((lineItem, index) => {
        const proRatedDiscount = discountIsPercent
          ? discount || 0
          : ((lineItem.amount + lineItem.wuwd) / total) * (discount || 0);
        actions.updateLineItem(index, { discount: proRatedDiscount, discountIsPercent }, false);
      });
      invoice.lineItems.fromTime?.forEach((lineItem, index) => {
        const proRatedDiscount = discountIsPercent
          ? discount || 0
          : ((lineItem.amount + lineItem.wuwd) / total) * (discount || 0);
        actions.updateLineItem(index, { discount: proRatedDiscount, discountIsPercent }, true);
      });
    }

    actions.updateInvoiceFormData('singleLineDiscount', discount);
    actions.updateInvoiceFormData('singleLineDiscountIsPercent', discountIsPercent);
  };

  return (
    <div className={styles.invoiceBuilderOptions}>
      {featureEnabled('toggle_credit_on_invoices') &&
        invoiceType === invoiceTypes.oneTime &&
        availableCredits?.length > 0 && (
          <InvoiceOption
            label="Use Credit"
            disabled={disabled}
            value={options.useCredit}
            onChange={() => {
              actions.toggleOption('useCredit');
            }}>
            <AvailableCredits credits={availableCredits} onCreditsChanged={actions.updateCreditsUsed} />
          </InvoiceOption>
        )}
      <InvoiceOption
        label="Single Line Invoice"
        disabled={disabled || !hasBalance}
        value={options.singleLine}
        onChange={() => {
          updateSingleLineDiscount('0', invoice.singleLineDiscountIsPercent);
          actions.toggleOption('singleLine');
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="cp-ml-16 cp-mr-16 cp-mb-16" style={{ display: 'flex', width: '100%' }}>
            <div className="cp-mr-16" style={{ width: '100%' }}>
              <label>
                Description<span className="cps-color-primary">*</span>
              </label>
              <CpTextarea
                rows={2}
                value={invoice.singleLineDescription}
                disabled={disabled}
                onChange={value => actions.updateInvoiceFormData('singleLineDescription', value)}
              />
            </div>
            <div>
              <label>Discount</label>
              <CpDropdown
                renderTrigger={({ toggle }) => (
                  <span>
                    <InputGroup
                      addon={
                        disabled ? (
                          <span>{invoice.singleLineDiscountIsPercent ? '%' : '$'}</span>
                        ) : (
                          <span onClick={toggle}>
                            <a>{invoice.singleLineDiscountIsPercent ? '%' : '$'}</a>
                          </span>
                        )
                      }
                      side={invoice.singleLineDiscountIsPercent ? 'right' : 'left'}
                      style={{ zIndex: 'inherit' }}
                      disabled={disabled}
                      maxLength="7"
                      value={internalDiscount}
                      onFocus={e => {
                        setInternalDiscount(decimalNumberFilter(e.target.value, 2, false, false));
                        setHighlightField(e.target);
                      }}
                      onChange={value => setInternalDiscount(decimalNumberFilter(value, 2, false, false))}
                      onBlur={e =>
                        updateSingleLineDiscount(
                          decimalNumberFilter(e.target.value, 2, false, false),
                          invoice.singleLineDiscountIsPercent,
                          true
                        )
                      }
                    />
                  </span>
                )}
                renderContent={() => (
                  <div className="cp-select-list">
                    <a
                      onClick={() =>
                        updateSingleLineDiscount(
                          decimalNumberFilter(invoice.singleLineDiscount.toString(), 2, false),
                          true
                        )
                      }>
                      Percentage %
                    </a>
                    <a
                      onClick={() =>
                        updateSingleLineDiscount(
                          decimalNumberFilter(invoice.singleLineDiscount.toString(), 2, false),
                          false
                        )
                      }>
                      Dollar $
                    </a>
                  </div>
                )}
              />
            </div>
          </div>
          <div className={styles.subTotalContainer} style={{ width: '132px' }}>
            <div>Tax</div>
            <div style={{ fontWeight: 'bold' }}>{toCurrency(lineItemSubTotal.sumTax)}</div>
            <div className="cp-mt-4">Subtotal</div>
            <div style={{ fontWeight: 'bold' }}>{toCurrency(lineItemSubTotal.sumTotal)}</div>
          </div>
        </div>
      </InvoiceOption>
      <InvoiceOption
        label="Client Note"
        disabled={disabled}
        value={options.clientNote}
        onChange={() => {
          actions.toggleOption('clientNote');
        }}>
        <div className={`${styles.invoiceRichText} cp-ml-16 cp-mr-16 cp-mb-16`}>
          <Suspense fallback={<CpLoader />}>
            <CpRichTextEditor
              editorRef={clientNote}
              initialHTML={invoice?.clientNote}
              onInput={() => actions.updateInvoiceFormData('clientNote', clientNote.current.getHTML())}
              buttons={['headings', 'bold', 'underline', 'italic', 'list-unordered', 'list-ordered']}
            />
          </Suspense>
        </div>
      </InvoiceOption>
      <InvoiceOption
        label="Terms &amp; Conditions"
        disabled={disabled}
        value={options.termsConditions}
        onChange={() => {
          actions.toggleOption('termsConditions');
        }}>
        <div className={`${styles.invoiceRichText} cp-ml-16 cp-mr-16 cp-mb-16`}>
          <Suspense fallback={<CpLoader />}>
            <CpRichTextEditor
              editorRef={termsAndConditions}
              initialHTML={invoice?.termsConditions}
              onInput={() => actions.updateInvoiceFormData('termsConditions', termsAndConditions.current.getHTML())}
              buttons={['headings', 'bold', 'underline', 'italic', 'list-unordered', 'list-ordered']}
            />
          </Suspense>
        </div>
      </InvoiceOption>
      {hasCanopyPayments &&
        invoiceType === invoiceTypes.recurring &&
        canViewPayment &&
        (!hasAdyen || teamCanKeyInCards) && (
          <InvoiceOption
            label="Recurring Payments"
            disabled={disabled || !invoice.client?.id || !canCreatePayment || lineItemSubTotal.sumTotal <= 0}
            value={options.recurringPayments}
            onChange={() => {
              actions.toggleOption('recurringPayments');
            }}>
            <div className="cp-ph-16">
              <RecurringPayment
                adyenActions={actions.adyenActions}
                adyenPaymentDetails={adyenPaymentDetails}
                amexEnabled={amexEnabled}
                client={invoice.client}
                disabled={disabled || !invoice.client?.id || !canCreatePayment}
                hasAdyen={hasAdyen}
                hasCanopyPayments={hasCanopyPayments}
                invoice={invoice}
                mode={mode}
                onPaysafeUpdate={actions.paysafeChanged}
                onRecurringPaymentChanged={actions.updateRecurringPayment}
                options={options}
                recurrence={recurrence}
                submitError={errors.paymentSubmitError}
                teamCanKeyInCards={teamCanKeyInCards}
              />
            </div>
          </InvoiceOption>
        )}
      {invoiceType === invoiceTypes.recurring &&
        recurrence.status !== 'Not Scheduled' &&
        recurrence.relationships?.invoices && (
          <>
            <div className={styles.invoiceBuilderOption}>
              <div className="cps-subheader-sm cps-wt-semibold">Recurrence History</div>
            </div>
            <div className="cp-ph-16 cp-mb-16">
              <RecurringHistory recurrence={recurrence} onViewInvoice={actions.viewInvoiceFromRecurrence} />
            </div>
          </>
        )}
    </div>
  );
};

const InvoiceOption = ({ label, disabled, value, onChange, children }) => (
  <>
    <div className={styles.invoiceBuilderOption}>
      <div className="cps-subheader-sm cps-wt-semibold">{label}</div>
      <div>
        <CpToggle checked={value} onChange={onChange} disabled={disabled} />
      </div>
    </div>
    {value && children}
  </>
);
