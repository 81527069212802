import { cloneDeep, some } from 'lodash';
import { fetchAsObservable } from 'fetcher!sofe';
import { pluck, map } from 'rxjs/operators';

export const getInvoice = params => {
  return fetchAsObservable('/api/invoices/' + params.invoiceId).pipe(pluck('invoices'));
};

export const getAllInvoices = (clientId = null, filters = {}, page = 1, limit = 50) => {
  let apiPath = clientId
    ? `/api/clients/${clientId}/invoices:search?limit=${limit}&page=${page}`
    : `/api/invoices:search?limit=50&page=${page}`;

  const params = fixMissingDate(filters);
  return fetchAsObservable(apiPath, {
    method: 'POST',
    body: params,
  });
};

export const getInvoicePDF = (invoiceId, asBlob = false) => {
  return fetchAsObservable(`/api/invoices/${invoiceId}?isPdf=true`, { responseType: asBlob ? 'blob' : 'json' });
};

export const getStatementPDFDeprecated = (clientId, asBlob = false) => {
  return fetchAsObservable(`/api/clients/${clientId}/invoices/?isPdf=true`, { responseType: asBlob ? 'blob' : 'json' });
};

export const getInvoiceCSV = filters => {
  return fetchAsObservable(`/api/invoices/export`, {
    method: 'POST',
    body: filters,
  });
};

export const getArchivedInvoiceCSV = filters => {
  return fetchAsObservable(`/api/invoices/export?onlyArchived=true`, {
    method: 'POST',
    body: filters,
  });
};

export const getRecurringInvoiceCSV = filters => {
  return fetchAsObservable(`/api/invoices/recurrences/export?with_payment_details_only=false`, {
    method: 'POST',
    body: filters,
  });
};

export const scheduleRecurringCSV = filters => {
  return fetchAsObservable('/api/invoices/recurrences/schedule_csv', {
    method: 'POST',
    body: filters,
  });
};

export const getRecurringInvoices = (clientId = null, filters = null, page = 1, withPaymentDetails) => {
  let apiPath = clientId
    ? `/api/invoices/recurrences:search?limit=50&page=${page}${clientId ? '&client_ids=' + clientId : ''}`
    : `/api/invoices/recurrences:search?limit=50&page=${page}`;

  if (withPaymentDetails) {
    apiPath += '&with_payment_details_only=true';
  } else if (withPaymentDetails === false) {
    apiPath += '&exclude_payment_details=true';
  }

  const params = fixMissingDate(filters);

  return fetchAsObservable(apiPath, {
    method: 'POST',
    body: params,
  });
};

export const createRecurringPayment = (invoiceRecurrenceId, recurrences) => {
  return fetchAsObservable(`/api/invoices/recurrences/${invoiceRecurrenceId}`, {
    method: 'PATCH',
    body: recurrences,
  }).pipe(pluck('recurrences'));
};

export const removeRecurringPayment = invoiceRecurrenceId => {
  return fetchAsObservable(`/api/invoices/recurrences/${invoiceRecurrenceId}`, {
    method: 'PATCH',
    body: { recurrences: { payment_details: null } },
  }).pipe(pluck('recurrences'));
};

export const getRecurringPaymentCSV = (filters, clientId = null) => {
  if (clientId && !filters.client_id) {
    filters.client_name = { filter_params: [clientId] };
  }

  return fetchAsObservable(`/api/invoices/recurrences/export?with_payment_details_only=${true}`, {
    method: 'POST',
    body: filters,
  });
};

function fixMissingDate(searchParams) {
  const params = { ...searchParams };
  ['invoice_date', 'due_date', 'start_date'].forEach(dateFilter => {
    if (params[dateFilter]?.after && !params[dateFilter]?.before) {
      params[dateFilter].before = params[dateFilter].after;
    }
    if (params[dateFilter]?.before && !params[dateFilter]?.after) {
      params[dateFilter].after = params[dateFilter].before;
    }
  });

  return params;
}

export const getRecurrence = params => {
  return fetchAsObservable(`/api/invoices/recurrences/${params.id}`).pipe(pluck('recurrences'));
};

export const getArchivedInvoices = (clientId = null, filters = null, page = 1) => {
  let apiPath = clientId
    ? `/api/clients/${clientId}/invoices:search?limit=50&page=${page}&onlyArchived=true`
    : `/api/invoices:search?limit=50&page=${page}&onlyArchived=true`;

  const params = fixMissingDate(filters);
  return fetchAsObservable(apiPath, {
    method: 'POST',
    body: params,
  });
};

export const archiveInvoice = invoiceId => {
  return fetchAsObservable(`/api/invoices/${invoiceId}/archive`, {
    method: 'POST',
  });
};

export const archiveInvoicesBulk = ids => {
  const invoices = ids.map(id => ({ id: id }));

  return fetchAsObservable(`/api/invoices/_bulk/archive`, {
    method: 'POST',
    body: { invoices },
  });
};

export const deleteInvoice = invoice => {
  return fetchAsObservable(`/api/invoices/${invoice.id}`, {
    method: 'DELETE',
  });
};

export const deleteInvoicesBulk = ids => {
  const invoices = ids.map(id => ({ id: id }));

  return fetchAsObservable(`/api/invoices/_bulk`, {
    method: 'DELETE',
    body: { invoices },
  });
};

export const unarchiveInvoice = id => {
  return fetchAsObservable(`/api/invoices/${id}/unarchive`, { method: 'POST' });
};

export const restoreInvoice = invoice => {
  return fetchAsObservable(`/api/invoices/${invoice.id}`, {
    method: 'PATCH',
    body: {
      invoices: { is_deleted: false },
    },
  });
};

export const restoreInvoicesBulk = (ids, archived) => {
  const invoices = archived // archived = true means undelete
    ? ids.map(id => ({ id: id, is_deleted: false }))
    : ids.map(id => ({ id: id }));

  return fetchAsObservable(`/api/invoices/_bulk${!archived ? '/unarchive' : ''}`, {
    method: archived ? 'PATCH' : 'POST',
    body: { invoices },
  });
};

export function getInvoiceFilter(type, searchParams, archived, searchValue) {
  let params = cloneDeep(searchParams);

  if (searchValue) {
    params = { ...params, search_string: searchValue };
  }

  return fetchAsObservable(`/api/invoices:search-filter/${type}?limit=50${archived ? `&onlyArchived=true` : ''}`, {
    method: 'POST',
    body: params,
  }).pipe(
    pluck(type),
    map(values => {
      const hasBlank = some(values, { id: null });
      const allValues = values
        .filter(value => value?.id !== null)
        .map(value => ({
          id: value.id ? value.id.toString() : value.name,
          label: !['clients', 'client_groups'].includes(type) ? value?.name?.capitalize() : value.name,
        }));

      if (hasBlank) {
        allValues.unshift({ id: null, label: '(Blanks)' });
      }

      return allValues;
    })
  );
}

export function getNextInvoiceNumber() {
  return fetchAsObservable('/api/meta/invoices').pipe(pluck('nextInvoiceNo'));
}

export function getRecurrenceFilter(type, searchParams) {
  return fetchAsObservable(`/api/invoices/recurrences:search-filter/${type}`, {
    method: 'POST',
    body: searchParams,
  }).pipe(
    pluck(type),
    map(values =>
      values.map(value => ({
        id: value.id ? value.id.toString() : value.name,
        label: type !== 'clients' ? value.name.capitalize() : value.name,
      }))
    )
  );
}

export function createInvoice(invoice, preview = false, bulk = false, bulkPreview = false) {
  return fetchAsObservable(
    `/api/${bulk ? 'bulk_invoices' : 'invoices'}${preview ? '?is_preview=true' : ''}${
      bulkPreview ? '&use_bulk_invoice_title=true' : ''
    }`,
    {
      method: 'POST',
      body: invoice,
      responseType: preview ? 'blob' : 'json',
    }
  );
}

export function updateInvoice(params, invoice) {
  return fetchAsObservable(`/api/invoices/${params.id}`, {
    method: 'PATCH',
    body: invoice,
  });
}

export function saveInvoice(params, invoice) {
  return fetchAsObservable(`/api/invoices/${params.id}`, {
    method: 'PUT',
    body: invoice,
  });
}

export function notify(invoiceId, notification) {
  return fetchAsObservable(`/api/invoices/${invoiceId}/notification`, {
    method: 'POST',
    body: notification,
  });
}

export function createRecurrence(recurrence) {
  return fetchAsObservable('/api/invoices/recurrences', {
    method: 'POST',
    body: recurrence,
  });
}

export function updateRecurrence(params, recurrence) {
  return fetchAsObservable(`/api/invoices/recurrences/${params.id}`, {
    method: 'PUT',
    body: recurrence,
  });
}

export function deleteRecurrence(recurrenceId) {
  return fetchAsObservable(`/api/invoices/recurrences/${recurrenceId}`, {
    method: 'DELETE',
  });
}

export function getWhiteLabelSettings() {
  return fetchAsObservable(`/white-label-settings`);
}

export function getCredit(creditId) {
  return fetchAsObservable(`/api/credits/${creditId}`).pipe(pluck('credits'));
}

export function getInvoiceSettings() {
  return fetchAsObservable(`/api/invoice_settings`).pipe(pluck('invoice_settings'));
}

export function updateInvoiceSettings(invoiceSettings) {
  return fetchAsObservable(`/api/invoice_settings`, {
    method: 'PUT',
    body: invoiceSettings,
  }).pipe(pluck('invoice_settings'));
}

export function getInvoiceReminderSettings() {
  return fetchAsObservable('/api/reminders?type=invoice');
}

export function updateInvoiceReminderSettings(invoiceSettings) {
  return fetchAsObservable('/api/reminders?type=invoice', {
    method: 'PUT',
    body: { reminders: invoiceSettings },
  });
}
