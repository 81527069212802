import React from 'react';
import AsyncDecorator from 'async-decorator/rx6';
import { map } from 'lodash';
import styles from './navigation.styles.css';
import { primaryNavHeightObs } from 'primary-navbar!sofe';
import { CpIcon, CpLoader } from 'canopy-styleguide!sofe';

@AsyncDecorator
export class BillingNavigation extends React.Component {
  state = {
    top: 0,
  };
  componentDidMount() {
    this.props.cancelWhenUnmounted(primaryNavHeightObs.subscribe(top => this.setState({ top })));
  }

  render() {
    const { selectedTab, selectedSubTab, handleClick, tabs, marginTop, loaded } = this.props;
    const { top } = this.state;
    const totalMargin = top + marginTop;
    return (
      <div
        className={`cps-secondarynav +new cps-flexible-sidenav cp-ml-12 ${styles.leftNav}`}
        style={{ top: totalMargin }}>
        <div className="cps-card cp-pv-8">
          {loaded ? (
            map(tabs, (tab, tabKey) => {
              const selected = selectedTab === tabKey;
              return (
                <div key={tabKey}>
                  <div
                    className={`${selected ? `cps-color-primary ${styles.selectedTab}` : `${styles.unselectedTab}`} ${
                      styles.tab
                    }`}
                    onClick={() => handleClick(tabKey)}>
                    <CpIcon
                      name={tab.icon}
                      fill={`var(--cps-color-${selected ? 'primary' : 'monsoon'})`}
                      className="cp-mr-12"
                    />
                    {tab.label}
                  </div>
                  {selected && tab.subTabs && (
                    <div className="cps-bg-color-ash" style={{ padding: '16px 0' }}>
                      {map(tab.subTabs, (subTab, subTabKey) => {
                        const subSelected = selectedSubTab === subTabKey;
                        return (
                          <div
                            key={subTabKey}
                            className={`${subSelected ? 'cps-wt-bold' : ''} ${styles.subTab}`}
                            onClick={() => handleClick(tabKey, subTabKey)}>
                            {subTab.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <CpLoader />
          )}
        </div>
      </div>
    );
  }
}
