import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import { DateTime } from 'luxon';
import { get, find, isEmpty } from 'lodash';
import { ErrorBoundary } from 'error-logging!sofe';
import useForceUpdate from 'use-force-update';
import { successToast } from 'toast-service!sofe';
import { useHasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import {
  CpButton,
  CpEmptyState,
  CpIcon,
  CpLoader,
  CpSelectSingle,
  CpToggle,
  CpWell,
  CpModal,
  CpTooltip,
} from 'canopy-styleguide!sofe';

import { handleError } from 'src/common/handle-error.helper';
import { setupPaysafe } from 'src/billing-ui';
import { SelectClient } from './select-client.component';
import { SelectInvoices } from './select-invoices.component';
import { SelectRecurringSeries } from './select-recurring-series.component';
import { SelectPaymentFrequency } from './select-payment-frequency.component';
import { PaymentDetails } from './payment-details.component';
import { VerifyConfirm } from './verify-confirm.component';
import { PaymentConfirmation } from './payment-confirmation.component';
import { AccountPaymentCard } from './account-payment-card.component';
import { TotalPaymentCard } from './total-payment-card.component';
import { ScheduledPaymentReconciler } from './scheduled-payment-reconciler.component';
import { usePrevious } from 'src/common/custom-hooks';
import {
  convertToCurrencyText,
  maybeSaveAccount,
  maybeSaveCard,
  paymentSteps,
  paymentStepConfig,
  paymentMethods,
  paymentTypes,
  paymentErrors,
  prepareAndCreatePayment,
  prepareAndCreateRecurringPayment,
  separateName,
  useCanopyPayments,
  paymentViewMode,
} from 'src/payments/payments.helper';
import { luxonDefaultDateFormat } from 'src/billing-helpers';
import { outstandingFilter } from 'src/invoices/invoices.helper';
import { getAllInvoices, getRecurringInvoices, removeRecurringPayment } from 'src/resources/invoices.resources';
import { printPayment, downloadPayment, deletePayment, deleteScheduledPayment } from 'src/resources/payments.resources';
import { getClient, getUsersAndAdmins } from 'src/resources/clients.resources';
import { updateCard, updateBankAccount } from 'src/resources/payment-settings.resources';
import { getCredit } from 'src/resources/credits.resources';
import { getIntegrations } from 'src/resources/integrations.resources.js';
import { useAdyenPayments } from 'src/payments/adyen-payments.hook.js';
import { notifyAnalytics } from 'src/resources/analytics.resources';

import styles from './add-payment.styles.css';

const IntegrationSyncErrorBanner = lazy(() =>
  SystemJS.import('integrations-ui!sofe').then(module => module.getIntegrationSyncErrorBanner())
);

export const AddPayment = props => {
  const [user, tenant] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';

  const { hasCanopyPayments, hasAdyen, teamCanKeyInCards, amexEnabled } = useCanopyPayments();
  const { adyenPaymentDetails, adyenActions } = useAdyenPayments({ paymentViewMode: paymentViewMode.Payments });

  const [step, setStep] = useState(paymentSteps.SelectClient);
  const lastStep = usePrevious(step);
  const [paymentType, setPaymentType] = useState(
    props.recurring
      ? {
          name: 'Recurring payment',
          id: paymentTypes.recurring,
        }
      : { name: 'One-time payment', id: paymentTypes.oneTime }
  );
  const [invoices, setInvoices] = useState([]);
  const [recurringInvoices, setRecurringInvoices] = useState([]);
  const [selectedRecurrence, setSelectedRecurrence] = useState();
  const [recurringPaymentDate, setRecurringPaymentDate] = useState('due date');
  const [loaded, setLoaded] = useState(false);
  const [accountPaymentDetails, setAccountPaymentDetails] = useState({ amount: 0, description: '', enabled: false });
  const [paymentTotal, setPaymentTotal] = useState('0.00');
  const [balanceTotal, setBalanceTotal] = useState('0.00');
  const [remainingTotal, setRemainingTotal] = useState('0.00');
  const [clientId, setClientId] = useState(null);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientName, setClientName] = useState(null);
  const [paymentError, setPaymentError] = useState(paymentErrors.declinedCard);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [payment, setPayment] = useState({
    amount: 0,
    check_number: '',
    date: DateTime.local().startOf('day'),
    display_method: '',
    invoice_payments: [],
    method: '',
    notes: '',
    relationships: {},
  });
  const [hasAppliedCredits, setHasAppliedCredits] = useState(false);
  const [sendReceipt, setSendReceipt] = useState(true);
  const [paysafeInstance, setPaysafeInstance] = useState(null);
  const [qboIntegrationInfo, setQboIntegrationInfo] = useState({});
  const [triggerIntegrations, setTriggerIntegrations] = useState(false);
  const [syncPaymentsToIntegration, setSyncPaymentsToIntegration] = useState(false);
  const [integrationsLoading, setIntegrationsLoading] = useState(false);
  const [syncedClient, setSyncedClient] = useState({ synced: false, loaded: false });
  const [initialInvoiceId, setInitialInvoiceId] = useState(props.paymentData?.invoice?.id);

  const forceUpdate = useForceUpdate();
  const getClientSub = useRef();

  const hasIntegrationPayments =
    qboIntegrationInfo?.connected &&
    !!qboIntegrationInfo.payments?.sync_status?.first_synced_at &&
    !!qboIntegrationInfo.payments?.sync_status?.synced_at;

  const isFuturePayment =
    paymentType.id === paymentTypes.recurring ||
    DateTime.local().startOf('day').diff(DateTime.fromISO(payment.date).startOf('day'), 'days').days < 0;

  const allowDelete = useHasAccess('billing_archive_delete') || isClient;

  useEffect(() => {
    return () => {
      getClientSub.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // prevent background scroll
    document.body.style.overflow = 'hidden';

    // hide walk me ? icon
    for (let el of document.querySelectorAll('.walkme-icon-image-div')) {
      el.style.visibility = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
      for (let el of document.querySelectorAll('.walkme-icon-image-div')) {
        el.style.visibility = '';
      }
    };
  }, []);

  useEffect(() => {
    // set intial client id
    let clientId = null;
    if (props.paymentData) {
      if (props.paymentData.clientId) {
        clientId = props.paymentData.clientId;
      } else if (props.paymentData.invoice) {
        clientId = props.paymentData.invoice.relationships.for.id;
      } else if (props.paymentData.template) {
        clientId = props.paymentData.template.relationships.for.id;
      }

      if (clientId) {
        if (props.paymentData.invoice && props.paymentData.invoice.client_name) {
          setClientId(clientId);
          setClientName(props.paymentData.invoice.client_name);
        } else {
          const clientSubscription = getClient(clientId).subscribe(client => {
            if (client.is_active) {
              setClientId(clientId);
              setClientName(client.name);
            }
          }, handleError);

          return () => clientSubscription.unsubscribe();
        }
      }
    }
  }, [props.paymentData.clientId, props.paymentData.invoice, props.paymentData.template]);

  useEffect(() => {
    if (!props.edit || (!props.paymentData.payment && !props.paymentData.template)) return;

    const { paymentData } = props;

    if (paymentData.template) {
      if (paymentData.template.payment_details) {
        // recurring payment
        const { payment_details } = paymentData.template;
        setPaymentType({
          name: 'Recurring payment',
          id: paymentTypes.recurring,
        });
        if (paymentData.template.payment_details.terms === 0) {
          setRecurringPaymentDate('invoice date');
        } else {
          setRecurringPaymentDate('due date');
        }
        setSelectedRecurrence({
          ...paymentData,
          payment_terms: paymentData.template.payment_terms,
          schedule: {
            ...paymentData.schedule,
            recurring_payment_first_payment: paymentData.invoice_template.payment_details.first_payment_occurrence,
          },
          terms: paymentData.template.terms,
          total: paymentData.template.invoice_total,
        });
        setPaymentMethod(`cpStored ${payment_details.card_id}`);
      } else {
        // scheduled payment
        const { payment_type, card_id, client_notes, scheduled_payment, credit_amount, credit_description } =
          paymentData.template;

        +credit_amount &&
          setAccountPaymentDetails({
            amount: +credit_amount,
            description: credit_description,
            enabled: !!credit_amount,
          });
        setPaymentMethod(`${payment_type === 'ACH' ? 'cpStoredBankAccount' : 'cpStoredCreditCard'} ${card_id}`);
        setPaymentDetails({
          ...paymentDetails,
          method: `${payment_type === 'ACH' ? 'cpStoredBankAccount' : 'cpStoredCreditCard'} ${card_id}`,
        });

        setPayment({
          ...payment,
          id: paymentData.id,
          method: `${payment_type === 'ACH' ? 'cpStoredBankAccount' : 'cpStoredCreditCard'} ${card_id}`,
          date: DateTime.fromISO(paymentData.next_occurrence).startOf('day').toISO(),
          notes: client_notes,
          isScheduledPayment: scheduled_payment,
        });
      }
    } else {
      // manual payment
      const manualMethod = paymentData.payment.display_method;
      setPaymentMethod(manualMethod.toLowerCase());
      setPayment(paymentData.payment);
      if (paymentData.payment.payment_credit) {
        getCredit(paymentData.payment.payment_credit.id).subscribe(credit => {
          if (credit.invoice_payments?.length > 0) {
            setHasAppliedCredits(true);
          }
        }, handleError);
      }
    }
  }, [props.edit]);

  useEffect(() => {
    // advance workflow when client id changes
    if (clientId && step === paymentSteps.SelectClient) {
      if (paymentType.id === paymentTypes.oneTime) {
        setStep(paymentSteps.SelectInvoices);
      } else {
        setStep(paymentSteps.SelectRecurrence);
      }
    }
    if (clientId) {
      setPaymentDetails({});
      setPayment({ ...payment, relationships: { for: { id: Number(clientId), type: 'client' } } });
      getUsersAndAdmins(clientId).subscribe(response => {
        setClientUsers(response.filter(user => user.role === 'Client').map(client => client.id));
      }, handleError);
    }
  }, [clientId]);

  useEffect(() => {
    // refresh invoice list when client changes or loading list
    if (
      clientId &&
      step === paymentSteps.SelectInvoices &&
      (lastStep === paymentSteps.SelectClient ||
        lastStep === paymentSteps.SelectInvoices ||
        lastStep === paymentSteps.SelectRecurrence ||
        lastStep === paymentSteps.PaymentDetails ||
        lastStep === paymentSteps.Receipt)
    ) {
      const accountPayment = props.paymentData?.payment?.payment_credit;
      if (accountPayment && lastStep !== paymentSteps.PaymentDetails) {
        setAccountPaymentDetails({
          amount: accountPayment.amount,
          description: accountPayment.description,
          enabled: !!accountPayment.amount,
        });
      }

      const prevSelectedInvoices = invoices
        .filter(invoice => {
          return invoice.selected;
        })
        .map(invoice => ({ id: invoice.id, amountToPay: invoice.amountToPay }));

      const prevPaidInvoices = props.edit
        ? (
            get(props, 'paymentData.payment.invoice_payments') ||
            get(props, 'paymentData.template.invoice_payments') ||
            []
          ).map(invoice => ({
            id: invoice.relationships.applied_to.id,
            amountToPay: invoice.amount,
          }))
        : [];

      const invoiceSubscription = getAllInvoices(clientId, outstandingFilter, 1, 100).subscribe(response => {
        const maybeMissingInvoices =
          get(props, 'paymentData.payment.invoice_payments') || get(props, 'paymentData.template.invoice_payments');
        if (maybeMissingInvoices) {
          maybeMissingInvoices.forEach(invoice => {
            const invoiceData = invoice.relationships.applied_to;
            if (!find(response.invoices, { id: invoiceData.id })) {
              invoiceData.total = invoiceData.invoice_total;
              response.invoices.push({ ...invoiceData });
            }
          });
        }

        handleInvoicesChanged(
          response.invoices
            .map(invoice => {
              const wasSelectedInvoice =
                lastStep !== paymentSteps.PaymentDetails ? get(props, 'paymentData.invoice.id') === invoice.id : false;
              const wasPrevSelectedInvoice = find(prevSelectedInvoices, { id: invoice.id });
              const wasEditedPaymentInvoice = find(prevPaidInvoices, { id: invoice.id });

              const isActiveInvoice = wasSelectedInvoice || !!wasPrevSelectedInvoice || !!wasEditedPaymentInvoice;
              const amountToPay = wasPrevSelectedInvoice
                ? wasPrevSelectedInvoice.amountToPay
                : wasEditedPaymentInvoice
                ? wasEditedPaymentInvoice.amountToPay
                : wasSelectedInvoice
                ? invoice.balance
                : '';

              const balance =
                wasEditedPaymentInvoice && !payment.isScheduledPayment
                  ? parseFloat(invoice.balance) + parseFloat(wasEditedPaymentInvoice.amountToPay)
                  : parseFloat(invoice.balance);

              return {
                ...invoice,
                due_date: DateTime.fromISO(invoice.due_date).toFormat(luxonDefaultDateFormat),
                total: parseFloat(invoice.total),
                balance: balance,
                selected: isActiveInvoice,
                amountToPay: amountToPay,
              };
            })
            .sort(activeSorter)
        );
      });

      return () => {
        invoiceSubscription.unsubscribe();
      };
    } else if (
      clientId &&
      step === paymentSteps.SelectRecurrence &&
      (lastStep === paymentSteps.SelectClient ||
        lastStep === paymentSteps.SelectInvoices ||
        lastStep === paymentSteps.SelectRecurrence ||
        lastStep === paymentSteps.PaymentDetails ||
        lastStep === paymentSteps.Receipt)
    ) {
      let recurringInvoiceSubscription = null;

      if (props.edit) {
        setRecurringInvoices([selectedRecurrence]);
        setLoaded(true);
      } else {
        recurringInvoiceSubscription = getRecurringInvoices(clientId, null, 1, false).subscribe(response => {
          const activeRecurrences = response.recurrences.filter(r => r.status === 'active');

          setRecurringInvoices(activeRecurrences);
          if (!selectedRecurrence) {
            setSelectedRecurrence(activeRecurrences[0]);
          }
          setLoaded(true);
        });
      }

      return () => {
        if (recurringInvoiceSubscription) {
          recurringInvoiceSubscription.unsubscribe();
        }
      };
    } else if (step === paymentSteps.SelectClient) {
      setInvoices([]);
      setPaymentDetails({});
      setLoaded(false);
    }
  }, [clientId, step]);

  useEffect(() => {
    if (loaded) {
      getIntegrationDefaults(invoices, hasIntegrationPayments);
    }
  }, [loaded, hasIntegrationPayments]);

  useEffect(() => {
    if (step === paymentSteps.Error) {
      window.dispatchEvent(new CustomEvent('billing-ui::payment-complete'));
    }
  }, [step]);

  useEffect(() => {
    if (invoices.length === 0 && accountPaymentDetails.amount === 0) {
      return;
    }
    updateTotals(invoices, accountPaymentDetails.amount);
  }, [invoices, accountPaymentDetails.amount]);

  useEffect(() => {
    if (!!tenant?.qbo_credentials_id) {
      setIntegrationsLoading(true);
      const subscription = getIntegrations().subscribe(
        integrationsObj => {
          const qboIntegrationInfo = integrationsObj?.find(i => i.type === 'qbo');
          if (qboIntegrationInfo) {
            setQboIntegrationInfo({ ...qboIntegrationInfo, checkIntegrationAuth: () => setTriggerIntegrations(true) });
          }

          if (qboIntegrationInfo?.connected && qboIntegrationInfo?.payments?.sync_status?.synced_at) {
            setSyncPaymentsToIntegration(syncedClient.loaded && syncedClient.synced);
            setAccountPaymentDetails({ amount: 0, description: '', enabled: false });
          } else {
            setIntegrationsLoading(false);
          }
        },
        e => {
          setIntegrationsLoading(false);
          handleError(e);
        }
      );
      return () => subscription.unsubscribe?.();
    } else if (!tenant?.qbo_credentials_id) {
      setQboIntegrationInfo({});
      setSyncPaymentsToIntegration(false);
      setSyncedClient({ synced: false, loaded: true });
    }
  }, [props.integrations, triggerIntegrations, tenant?.qbo_credentials_id]);

  useEffect(() => {
    if (
      qboIntegrationInfo?.connected &&
      qboIntegrationInfo?.payments?.sync_status?.first_synced_at &&
      props.edit &&
      props.paymentData?.payment
    ) {
      setSyncPaymentsToIntegration(
        ((syncedClient.loaded && syncedClient.synced) || props.edit) && !!props.paymentData?.payment?.third_party_id
      );
    }
  }, [props.edit, hasIntegrationPayments]);

  const fetchClient = clientId => {
    getClientSub.current = getClient(clientId).subscribe(client => {
      if (client) {
        setSyncPaymentsToIntegration(!!client.third_party_id);
        setSyncedClient({ synced: !!client.third_party_id, loaded: true });
      }
    }, handleError);
  };

  useEffect(() => {
    if (clientId && hasIntegrationPayments && !props.edit) {
      fetchClient(clientId);
    }
  }, [clientId, hasIntegrationPayments, props.edit]);

  const activeSorter = (a, b) => {
    // put the active invoice on top, then sort by due_date
    if (a.selected && !b.selected) {
      return -1;
    } else if (b.selected && !a.selected) {
      return 1;
    } else {
      return a.due_date - b.due_date;
    }
  };

  const nextStep = () => {
    if (!payment.display_method) {
      setPayment({ ...payment, ...getPaymentMethodValues(paymentDetails) });
    }
    if (step === paymentSteps.ConfirmDetails) {
      setStep(paymentSteps.Processing);
      submitPayment();
    } else if (step === paymentSteps.Receipt) {
      props.onModalClose();
    } else if (step === paymentSteps.Error) {
      setPaymentError(paymentErrors.declinedCard);
      setStep(paymentSteps.PaymentDetails);
    } else if (
      ((paymentDetails.method || '').includes('cpStoredCreditCard') && !paymentDetails.ccInfo.isExpired) ||
      paymentStepConfig[step].validate({
        clientId,
        total: getTotalPayment(),
        method: paymentDetails.method,
        paymentDate: payment.date,
        ccInfo: paymentDetails.ccInfo,
        achInfo: paymentDetails.achInfo,
        selectedRecurrence: selectedRecurrence,
        paysafe: paysafeInstance,
        ...(hasAdyen && {
          adyenInstance: adyenPaymentDetails.adyenInstance,
          paymentMethod: adyenPaymentDetails.paymentMethod,
        }),
      })
    ) {
      if (step === paymentSteps.SelectInvoices || step === paymentSteps.SelectRecurrence) {
        if (hasAdyen && teamCanKeyInCards) {
          adyenActions.handleAdyenSession({
            clientId,
            step,
            invoices,
            selectedRecurrence,
            recurringPaymentDate,
            accountPaymentDetails,
            isEdit: props.edit,
          });

          isEmpty(adyenPaymentDetails.paymentMethod) && adyenActions.resetPaymentInfo();
          !props.edit && setPayment(prevState => ({ ...prevState, notes: '' }));
        }
        step === paymentSteps.SelectInvoices ? setStep(step + 2) : setStep(step + 1);
      } else {
        setStep(step + 1);
      }
    }
  };

  const prevStep = () => {
    if (paymentStepConfig[step].prevButton()) {
      if (step === paymentSteps.PaymentDetails && paymentType.id === paymentTypes.oneTime) {
        setStep(step - 2);
      } else {
        setStep(step - 1);
      }

      if (step === paymentSteps.ConfirmDetails && hasAdyen && teamCanKeyInCards) {
        adyenActions.resetPaymentInfo();
        !props.edit && setPayment(prevState => ({ ...prevState, notes: '' }));
      }
    }
  };

  const submitPayment = () => {
    if (hasAdyen && teamCanKeyInCards && !['cash', 'check', 'other'].includes(paymentDetails.method)) {
      adyenActions.submitPayment({
        payment,
        sendReceipt,
        onComplete: response => {
          if (response) {
            const { payment_number, credit_number, payment_id } = response;
            setPayment(prevState => ({
              ...prevState,
              id: payment_id,
              payment_number,
              credit_number,
            }));
          }
          setStep(paymentSteps.Receipt);
        },
        onError: error => {
          error.showToast = false;
          handleError(error);
          setPaymentError({
            ...paymentErrors.failedGeneric,
            details: 'Unable to process payment',
          });
          setStep(paymentSteps.Error);
        },
        isEdit: props.edit,
        selectedRecurrence,
        recurringPaymentDate,
        creditDescription: accountPaymentDetails?.description,
        syncPaymentsToIntegration,
        hasIntegrationPayments,
      });
      return;
    }

    const { achInfo, ccInfo, method } = paymentDetails;
    if (method === 'cpCreditCard') {
      if (!paysafeInstance) {
        setPaymentError({
          ...paymentErrors.failedGeneric,
          details: 'Unable to process payment',
        });
        setStep(paymentSteps.Error);
        return;
      }
      const vaultConfig = {
        vault: {
          holderName: ccInfo.name,
          billingAddress: {
            country: 'US',
            zip: ccInfo.zip,
            state: ccInfo.state,
            city: ccInfo.city,
            street: ccInfo.street,
            street2: ccInfo.street2,
          },
        },
      };
      paysafeInstance.tokenize(vaultConfig, function (instance, error, result) {
        if (error) {
          setPaymentError({
            ...paymentErrors.declinedCard,
            details:
              error.code === '9003' && error.message.includes('expiry year') ? 'Card Expired' : error.displayMessage,
          });
          setStep(paymentSteps.Error);
        } else {
          maybeSaveCard(ccInfo, payment.relationships.for.id, result.token)
            .then(card => {
              if (paymentType.id === paymentTypes.oneTime) {
                prepareAndCreatePayment(
                  payment,
                  isFuturePayment,
                  sendReceipt ? clientUsers : null,
                  card.token || result.token,
                  card.id,
                  'canopy_cc',
                  ccInfo.name,
                  props.edit,
                  accountPaymentDetails.description,
                  syncPaymentsToIntegration,
                  hasIntegrationPayments
                )
                  .then(response => {
                    setPayment({
                      ...payment,
                      id: response.id,
                      payment_number: response.payment_number,
                      payment_credit: response.payment_credit,
                    });
                    setStep(paymentSteps.Receipt);
                  })
                  .catch(error => {
                    setPaymentError({
                      ...paymentErrors.declinedCard,
                      details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                    });
                    setStep(paymentSteps.Error);
                  });
              } else {
                prepareAndCreateRecurringPayment(
                  payment,
                  card.id,
                  selectedRecurrence,
                  recurringPaymentDate,
                  hasIntegrationPayments
                )
                  .then(() => {
                    setStep(paymentSteps.Receipt);
                  })
                  .catch(error => {
                    setPaymentError({
                      ...paymentErrors.declinedCard,
                      details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                    });
                    setStep(paymentSteps.Error);
                  });
              }
            })
            .catch(error => {
              setPaymentError({
                ...paymentErrors.saveCard,
                details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
              });
              setStep(paymentSteps.Error);
            });
        }
      });
    } else if (method === 'cpAch') {
      const achPayment = {
        ...payment,
        ach_details: {
          account_type: achInfo.accountType.toUpperCase(),
          account_number: achInfo.accountNumber,
          routing_number: achInfo.routingNumber,
          account_holder_name: `${achInfo.firstName} ${achInfo.lastName}`,
          first_name: achInfo.firstName,
          last_name: achInfo.lastName,
          street: achInfo.street,
          city: achInfo.city,
          state: achInfo.state,
          zip: achInfo.zip,
          country: 'US',
        },
      };
      maybeSaveAccount(achInfo, payment.relationships.for.id)
        .then(account => {
          if (account) {
            setPaymentDetails({
              ...paymentDetails,
              methodNickname: `${account.nickname || account.account_type} *${account.last_two}`,
            });
          }
          if (paymentType.id === paymentTypes.oneTime) {
            prepareAndCreatePayment(
              account?.id ? payment : achPayment,
              isFuturePayment,
              sendReceipt ? clientUsers : null,
              null,
              account?.id,
              account?.id ? 'stored_dd' : 'canopy_dd',
              '',
              props.edit,
              accountPaymentDetails.description,
              syncPaymentsToIntegration,
              hasIntegrationPayments
            )
              .then(response => {
                setPayment({
                  ...payment,
                  id: response.id,
                  payment_number: response.payment_number,
                  payment_credit: response.payment_credit,
                });
                setStep(paymentSteps.Receipt);
              })
              .catch(error => {
                setPaymentError({
                  ...paymentErrors.failedGeneric,
                  details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                });
                setStep(paymentSteps.Error);
              });
          } else {
            prepareAndCreateRecurringPayment(
              payment,
              (account || {}).id,
              selectedRecurrence,
              recurringPaymentDate,
              hasIntegrationPayments
            )
              .then(() => {
                setStep(paymentSteps.Receipt);
              })
              .catch(error => {
                setPaymentError({
                  ...paymentErrors.failedGeneric,
                  details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                });
                setStep(paymentSteps.Error);
              });
          }
        })
        .catch(error => {
          setPaymentError({
            ...paymentErrors.saveBank,
            details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
          });
          setStep(paymentSteps.Error);
        });
    } else if (method.includes('cpStoredCreditCard') && ccInfo.isExpired) {
      const vaultConfig = {
        vault: {
          holderName: ccInfo.name,
          billingAddress: {
            country: 'US',
            zip: ccInfo.zip,
            state: ccInfo.state,
            city: ccInfo.city,
            street: ccInfo.street,
            street2: ccInfo.street2,
          },
        },
      };
      paysafeInstance.tokenize(vaultConfig, function (instance, error, result) {
        if (error) {
          setPaymentError({
            ...paymentErrors.declinedCard,
            details:
              error.code === '9003' && error.message.includes('expiry year') ? 'Card Expired' : error.displayMessage,
          });
          setStep(paymentSteps.Error);
        } else {
          updateCard(ccInfo.id, {
            card: {
              card_details: {
                ...separateName(ccInfo.name),
              },
              cc_token: result.token,
              is_preferred: ccInfo.isPreferred,
              nickname: ccInfo.nickname,
            },
          }).subscribe(
            () => {
              window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));

              if (paymentType.id === paymentTypes.oneTime) {
                prepareAndCreatePayment(
                  payment,
                  isFuturePayment,
                  sendReceipt ? clientUsers : null,
                  result.token,
                  ccInfo.id,
                  'stored_cc',
                  '',
                  props.edit,
                  accountPaymentDetails.description,
                  syncPaymentsToIntegration,
                  hasIntegrationPayments
                )
                  .then(response => {
                    setPayment({
                      ...payment,
                      id: response.id,
                      payment_number: response.payment_number,
                      payment_credit: response.payment_credit,
                    });
                    setStep(paymentSteps.Receipt);
                  })
                  .catch(error => {
                    setPaymentError({
                      ...paymentErrors.declinedCard,
                      details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                    });
                    setStep(paymentSteps.Error);
                  });
              } else {
                prepareAndCreateRecurringPayment(
                  payment,
                  ccInfo.id,
                  selectedRecurrence,
                  recurringPaymentDate,
                  hasIntegrationPayments
                )
                  .then(() => {
                    setStep(paymentSteps.Receipt);
                  })
                  .catch(error => {
                    setPaymentError({
                      ...paymentErrors.declinedCard,
                      details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
                    });
                    setStep(paymentSteps.Error);
                  });
              }
            },
            err => {
              if (get(err, 'data.errors.message', '').includes('in use')) {
                setPaymentError({
                  ...paymentErrors.saveCard,
                  details: 'This credit card already exists. Please enter a new one.',
                });
              }
              setStep(paymentSteps.Error);
              handleError(err);
            }
          );
        }
      });
    } else if (method.includes('cpStoredCreditCard') || method.includes('cpStoredBankAccount')) {
      const id = method.split(' ')[1];
      const payType = method.includes('cpStoredCreditCard') ? 'stored_cc' : 'stored_dd';
      if (payType === 'stored_cc' && ccInfo.saveCard) {
        updateCard(id, {
          card: {
            is_preferred: ccInfo.isPreferred,
          },
        }).subscribe(() => {
          window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
        }, handleError);
      }

      if (payType === 'stored_dd' && achInfo.saveAccount) {
        updateBankAccount(id, {
          ach: {
            is_preferred: achInfo.isPreferred,
          },
        }).subscribe(() => {
          window.dispatchEvent(new CustomEvent('billing-ui::payment-method-saved'));
        }, handleError);
      }

      if (paymentType.id === paymentTypes.oneTime) {
        prepareAndCreatePayment(
          payment,
          isFuturePayment,
          sendReceipt ? clientUsers : null,
          null,
          id,
          payType,
          '',
          props.edit,
          accountPaymentDetails.description,
          syncPaymentsToIntegration,
          hasIntegrationPayments
        )
          .then(response => {
            setPayment({
              ...payment,
              id: response.id,
              payment_number: response.payment_number,
              payment_credit: response.payment_credit,
            });
            setStep(paymentSteps.Receipt);
          })
          .catch(error => {
            setPaymentError({
              ...paymentErrors.failedGeneric,
              details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
            });
            setStep(paymentSteps.Error);
          });
      } else {
        prepareAndCreateRecurringPayment(payment, id, selectedRecurrence, recurringPaymentDate, hasIntegrationPayments)
          .then(() => {
            setStep(paymentSteps.Receipt);
          })
          .catch(error => {
            setPaymentError({
              ...paymentErrors.failedGeneric,
              details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
            });
            setStep(paymentSteps.Error);
          });
      }
    } else {
      if (paymentType.id === paymentTypes.oneTime) {
        prepareAndCreatePayment(
          payment,
          isFuturePayment,
          sendReceipt ? clientUsers : null,
          null,
          null,
          null,
          '',
          props.edit,
          accountPaymentDetails.description,
          syncPaymentsToIntegration,
          hasIntegrationPayments
        )
          .then(response => {
            setPayment({
              ...payment,
              id: response.id,
              payment_number: response.payment_number,
              payment_credit: response.payment_credit,
            });
            setStep(paymentSteps.Receipt);
          })
          .catch(error => {
            setPaymentError({
              ...paymentErrors.failedGeneric,
              details: get(error, 'data.errors.message', '') || get(error, 'data.errors.userMessage'),
            });
            setStep(paymentSteps.Error);
          });
      } else {
        setPaymentError({
          ...paymentErrors.failedGeneric,
          details: 'Cannot create a recurring payment with a manual method',
        });
        setStep(paymentSteps.Error);
      }
    }
  };

  const resetPayment = () => {
    setInvoices([]);
    setRecurringInvoices([]);
    setSelectedRecurrence();
    setLoaded(false);
    setPaymentDetails({});
    setPayment({
      amount: 0,
      check_number: '',
      date: DateTime.local().startOf('day'),
      display_method: '',
      invoice_payments: [],
      method: '',
      notes: '',
      relationships: payment.relationships,
    });
    setAccountPaymentDetails({ amount: 0, description: '', enabled: false });
    if (isClient) {
      setStep(paymentSteps.SelectInvoices);
    } else {
      setClientId(null);
      setStep(paymentSteps.SelectClient);
    }
  };

  const getTotalPayment = () => {
    if (!invoices.length) {
      return accountPaymentDetails.amount;
    }
    return invoices.reduce((acc, invoice) => {
      if (invoice.amountToPay) {
        return acc + parseFloat(invoice.amountToPay);
      } else {
        return acc;
      }
    }, +accountPaymentDetails.amount);
  };

  const getPaidInvoices = () => {
    if (!invoices.length) {
      return '';
    }
    return invoices
      .reduce((acc, invoice) => {
        if (invoice.amountToPay && invoice.amountToPay > 0) {
          acc.push(invoice.invoice_number);
        }
        return acc;
      }, [])
      .join(', ');
  };

  const handleClientChanged = client => {
    if (client?.id !== clientId) {
      setInvoices([]);
      setRecurringInvoices([]);
      setSelectedRecurrence();
    }

    if (client) {
      setClientId(client.id);
      setClientName(client.name);
      if (paymentType.id === paymentTypes.recurring && !isClient) {
        setStep(paymentSteps.SelectRecurrence);
      } else {
        setStep(paymentSteps.SelectInvoices);
      }
    } else {
      setClientId(null);
      setClientName('');
      setLoaded(false);
      setStep(paymentSteps.SelectClient);
    }
  };

  const handlePaymentTypeChanged = type => {
    if (paymentType.id === type.id) return;

    setLoaded(false);
    setInvoices([]);
    setRecurringInvoices([]);
    setSelectedRecurrence();
    setPaymentType(type);
    setPayment({
      amount: 0,
      check_number: '',
      date: DateTime.local().startOf('day'),
      display_method: '',
      invoice_payments: [],
      method: '',
      notes: '',
      relationships: payment.relationships,
    });
    setAccountPaymentDetails({ amount: 0, description: '', enabled: false });

    if (step > paymentSteps.SelectClient) {
      if (type.id === paymentTypes.oneTime) {
        setStep(paymentSteps.SelectInvoices);
      } else {
        setStep(paymentSteps.SelectRecurrence);
      }
    }
  };

  const getIntegrationDefaults = (invoices, hasIntegrationPayments) => {
    const invoice =
      invoices?.length && initialInvoiceId ? invoices.find(invoice => invoice.id === initialInvoiceId) : null;

    if (hasIntegrationPayments && !!invoice) {
      if (invoice?.third_party_id) {
        setSyncPaymentsToIntegration(true);
        setIntegrationsLoading(false);
      } else {
        setSyncPaymentsToIntegration(false);
      }
    }
    setIntegrationsLoading(false);
  };

  const handleRecurrenceSelected = id => {
    const rec = find(recurringInvoices, { id });
    setSelectedRecurrence(rec);
  };

  const handleInvoicesChanged = invoices => {
    setInvoices(invoices);
    setLoaded(true);
  };

  const accountPaymentDetailsChanged = (key, value) => {
    setAccountPaymentDetails(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateTotals = (invoices, accountPaymentAmount) => {
    const newPaymentTotal = invoices.reduce((acc, invoice) => {
      if (invoice.amountToPay) {
        return acc + +invoice.amountToPay;
      }
      return acc;
    }, +accountPaymentAmount);
    const newBalanceTotal = invoices.reduce((acc, invoice) => {
      return acc + +invoice.balance;
    }, 0);
    const newRemainingTotal = newBalanceTotal - newPaymentTotal;

    setPaymentTotal(convertToCurrencyText(newPaymentTotal, false));
    setBalanceTotal(convertToCurrencyText(newBalanceTotal, false));
    setRemainingTotal(convertToCurrencyText(newRemainingTotal, false));
    setPayment({
      ...payment,
      amount: newPaymentTotal,
      invoice_payments: invoices
        .filter(invoice => invoice.amountToPay)
        .map(invoice => {
          const index = payment.invoice_payments.findIndex(p => invoice.id === p.relationships.applied_to.id);
          return {
            amount: invoice.amountToPay,
            ...(index > -1 && { id: payment.invoice_payments[index].id }),
            relationships: {
              applied_to: {
                id: invoice.id,
                type: 'invoice',
              },
            },
          };
        }),
      relationships: { for: { id: Number(clientId), type: 'client' } },
    });
  };

  const paymentDetailsChanged = details => {
    setPaymentDetails(details);

    if (details.ccInfo) {
      setPayment({
        ...payment,
        check_number: details.refNumber,
        ...getPaymentMethodValues(details),
      });
    }
  };

  const handlePaymentDateChanged = date => {
    setPayment({ ...payment, date });
  };

  const handleNoteChanged = note => {
    setPayment({ ...payment, notes: note });
  };

  const handleSendReceiptChanged = e => {
    e.persist();
    setSendReceipt(e.target.checked);
  };

  const getPaymentMethodValues = details => {
    if (!details || !details.ccInfo) return {};

    const display = find(paymentMethods, ['key', details.method]);

    if (display) {
      return {
        display_method: display.value,
        method: ['cpCreditCard', 'cpAch'].includes(details.method) ? details.method : 'manual',
      };
    } else {
      return {};
    }
  };

  const shouldScroll = () => {
    if ([paymentSteps.SelectInvoices, paymentSteps.ConfirmDetails, paymentSteps.Receipt].includes(step)) return true;
    if (
      step === paymentSteps.PaymentDetails &&
      (['cpCreditCard', 'cpAch'].includes(paymentDetails.method) ||
        (paymentDetails.method?.includes('cpStoredCreditCard') && paymentDetails.ccInfo.isExpired))
    )
      return true;

    if (
      step === paymentSteps.PaymentDetails &&
      hasAdyen &&
      teamCanKeyInCards &&
      adyenPaymentDetails.paymentMethod?.isExpired
    )
      return true;

    return false;
  };

  return (
    <CpModal onClose={props.onModalClose} show={true} width={832}>
      <CpModal.Header
        title={
          <span>
            {step === paymentSteps.Receipt && <CpIcon name="checkmark-large" fill="var(--cp-color-app-success-text)" />}
            {step === paymentSteps.Error && (
              <CpIcon name="alert-triangle-open-large" fill="var(--cp-color-app-warning-text)" />
            )}
            <span style={step === paymentSteps.Receipt || step === paymentSteps.Error ? { marginLeft: '48px' } : {}}>
              {paymentStepConfig[step].title(
                props.edit,
                isFuturePayment || paymentType.id === paymentTypes.recurring,
                isClient
              ) || paymentError.title}
            </span>
          </span>
        }
      />
      <CpModal.Body>
        {qboIntegrationInfo?.connected && qboIntegrationInfo?.disconnect_error && (
          <div className="cp-mh-36 cp-mt-16">
            <Suspense fallback={<></>}>
              <IntegrationSyncErrorBanner
                integrationInfo={qboIntegrationInfo}
                checkIntegrationAuth={qboIntegrationInfo.checkIntegrationAuth}
              />
            </Suspense>
          </div>
        )}
        {step === paymentSteps.ConfirmDetails && (
          <ScheduledPaymentReconciler
            selectedInvoices={invoices.filter(invoice => {
              return invoice.selected;
            })}
            paymentId={props.paymentData.id}
          />
        )}
        <div className={`cp-pb-0 ${shouldScroll() ? styles.addPaymentBodyScroll : ''}`}>
          {(step === paymentSteps.SelectClient ||
            (!isClient &&
              (step === paymentSteps.SelectInvoices ||
                step === paymentSteps.SelectRecurrence ||
                step === paymentSteps.PaymentDetails))) && (
            <div style={{ display: 'flex', width: '100%' }}>
              <SelectClient clientId={clientId} onClientChanged={handleClientChanged} readOnly={props.edit} />
              {hasCanopyPayments && (
                <div className="cp-ml-24" style={{ width: '233px' }}>
                  <div className="cp-mb-4">
                    <label htmlFor="paymentTypeSelect">Payment type</label>
                  </div>
                  <CpSelectSingle
                    disabled={props.edit}
                    data={[
                      { name: 'One-time payment', id: paymentTypes.oneTime },
                      ...(((!hasAdyen || (hasAdyen && teamCanKeyInCards)) && [
                        {
                          name: 'Recurring payment',
                          id: paymentTypes.recurring,
                        },
                      ]) ||
                        []),
                    ]}
                    value={paymentType}
                    placeholder="State"
                    onChange={value => {
                      handlePaymentTypeChanged(value);
                    }}
                    triggerIsBlock
                    contentWidth="block"
                  />
                </div>
              )}
            </div>
          )}
          {!!step &&
            qboIntegrationInfo?.connected &&
            !!qboIntegrationInfo.payments?.sync_status?.first_synced_at &&
            (step === paymentSteps.SelectInvoices || step === paymentSteps.SelectRecurrence) && (
              <CpWell level={2} className={styles.integrationSyncBox}>
                <div className="cp-flex-center">
                  <img
                    src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                    height="24px"
                    alt="QuickBooks Online Logo"
                  />
                  {props.edit ? (
                    <div className="cp-ml-8">
                      <strong>
                        {step === paymentSteps.SelectRecurrence
                          ? 'Sync payment series'
                          : syncPaymentsToIntegration
                          ? 'Payment is synced'
                          : 'Payment is NOT synced'}
                      </strong>
                    </div>
                  ) : (
                    <strong className="cp-ml-8">
                      {step === paymentSteps.SelectRecurrence ? 'Sync payment series' : 'Sync payment'}
                    </strong>
                  )}
                  {step === paymentSteps.SelectRecurrence && !qboIntegrationInfo.payments?.sync_status?.synced_at && (
                    <CpTooltip
                      className="cp-ml-8"
                      text="Once payment syncing is resumed, all recurring payments created going forward will sync. These payments will be created in QBO on the date of the recurrence.">
                      <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-secondary-text)" />
                    </CpTooltip>
                  )}
                </div>
                <div className="cp-flex-center">
                  {step === paymentSteps.SelectRecurrence ? (
                    <>
                      {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                        <div className="cp-caption cp-mr-8" style={{ width: '44rem' }}>
                          <strong>Payment syncing is currently paused.</strong> Recurring payments scheduled during the
                          "pause" will not sync. An admin may resume syncing in{' '}
                          <a href="/#/global-settings/billing/integrations" target="_blank">
                            billing settings.
                          </a>
                        </div>
                      )}
                      {!!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                        <div className="cp-caption cp-mr-8" style={{ width: '38.5rem' }}>
                          All recurring payments will be created in QBO on the date of the recurrence so long as the
                          integration is connected during that time
                        </div>
                      )}
                    </>
                  ) : props.edit ? (
                    <>
                      {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                        <div className="cp-caption cp-flex-center">
                          <div style={{ width: '31rem' }}>
                            <strong>Payment syncing is currently paused.</strong> Edits made to the payment during the
                            "pause" will not sync to QBO.
                          </div>
                          <CpIcon name="misc-padlock" fill="var(--cp-color-app-icon)" className="cp-ml-8" />
                        </div>
                      )}
                      {!!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                        <div className="cp-caption cp-flex-center">
                          Sync cannot be edited
                          <CpIcon name="misc-padlock" fill="var(--cp-color-app-icon)" className="cp-ml-8" />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="cp-flex-center">
                      {qboIntegrationInfo.payments?.sync_status?.synced_at &&
                        syncedClient.loaded &&
                        !syncedClient.synced && (
                          <div className="cp-caption cp-mr-8" style={{ width: '31.5rem' }}>
                            This payment will not be able to sync to QBO because the selected client is currently not
                            synced
                          </div>
                        )}
                      {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                        <div className="cp-caption cp-mr-8" style={{ width: '30rem' }}>
                          <strong>Payment syncing is currently paused.</strong> An admin may resume syncing in{' '}
                          <a href="/#/global-settings/billing/integrations" target="_blank">
                            billing settings.
                          </a>
                        </div>
                      )}
                      <CpToggle
                        checked={syncPaymentsToIntegration}
                        disabled={
                          !syncedClient.loaded ||
                          !syncedClient.synced ||
                          !qboIntegrationInfo.payments?.sync_status?.synced_at
                        }
                        onChange={checked => {
                          setSyncPaymentsToIntegration(checked);
                          notifyAnalytics(
                            user,
                            'qbo',
                            'qbo_payments',
                            checked ? 'qbo_payment_sync_on' : 'qbo_payment_sync_off',
                            {}
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </CpWell>
            )}
          {step === paymentSteps.SelectRecurrence && (
            <>
              {!loaded ? (
                <div className="cp-pb-24">
                  <CpLoader />
                </div>
              ) : recurringInvoices.length === 0 || !selectedRecurrence ? (
                <CpEmptyState
                  img="es_billing_invoice"
                  text="Recurring invoices"
                  subText="There are no recurring invoices."
                />
              ) : (
                <>
                  <SelectRecurringSeries
                    recurringInvoices={recurringInvoices}
                    selectedRecurrence={selectedRecurrence}
                    handleRecurrenceSelected={handleRecurrenceSelected}
                    isEdit={props.edit}
                  />
                  <SelectPaymentFrequency
                    recurrence={selectedRecurrence}
                    paymentDate={recurringPaymentDate}
                    setPaymentDate={setRecurringPaymentDate}
                  />
                </>
              )}
            </>
          )}
          {step === paymentSteps.SelectInvoices && (
            <>
              {!loaded || integrationsLoading ? (
                <div className="cp-pb-24">
                  <CpLoader />
                </div>
              ) : (
                <SelectInvoices
                  invoices={invoices}
                  clientId={clientId}
                  paymentId={props.paymentData?.id}
                  loaded={loaded}
                  edit={props.edit}
                  onInvoicesChanged={handleInvoicesChanged}
                  balanceTotal={balanceTotal}
                  showQboCol={hasIntegrationPayments}
                  syncPaymentsToIntegration={syncPaymentsToIntegration}
                  initialInvoiceId={initialInvoiceId}
                  setInitialInvoiceId={setInitialInvoiceId}
                />
              )}
            </>
          )}
          <PaymentDetails
            achInfo={paymentDetails.achInfo}
            adyenActions={adyenActions}
            adyenPaymentDetails={adyenPaymentDetails}
            amexEnabled={amexEnabled}
            ccInfo={paymentDetails.ccInfo}
            clearNotes={() => {
              setPayment(prevState => {
                return {
                  ...prevState,
                  notes: '',
                };
              });
            }}
            clientId={clientId}
            date={payment.date}
            detailsChanged={paymentDetailsChanged}
            edit={props.edit}
            handlePaymentDateChanged={handlePaymentDateChanged}
            hasAdyen={hasAdyen}
            hasCanopyPayments={hasCanopyPayments}
            isFuturePayment={isFuturePayment}
            key={clientId}
            method={paymentMethod}
            note={payment.notes}
            noteChanged={handleNoteChanged}
            onPaysafeInstance={setPaysafeInstance}
            onPaysafeUpdate={() => forceUpdate()}
            paymentTotal={paymentTotal}
            paymentType={paymentType.id}
            refNumber={payment.check_number}
            remainingTotal={remainingTotal}
            show={step === paymentSteps.PaymentDetails}
            teamCanKeyInCards={teamCanKeyInCards}
          />
          {step === paymentSteps.ConfirmDetails && (
            <VerifyConfirm
              clientName={clientName}
              paymentTotal={paymentTotal}
              paidInvoices={getPaidInvoices()}
              paymentMethod={paymentDetails.method}
              paymentNickname={
                hasAdyen && adyenPaymentDetails?.paymentMethod?.nickname
                  ? `${adyenPaymentDetails?.paymentMethod?.nickname} *${adyenPaymentDetails?.paymentMethod?.last_four}`
                  : paymentDetails.methodNickname
              }
              cardType={paymentDetails.ccInfo.cardType}
              refNumber={paymentDetails.refNumber}
              paymentDate={DateTime.fromISO(payment.date).toLocaleString(DateTime.DATE_SHORT)}
              sendReceipt={sendReceipt}
              onSendReceiptChanged={handleSendReceiptChanged}
              isFuturePayment={isFuturePayment}
              recurrence={selectedRecurrence}
              recurringPaymentDate={recurringPaymentDate}
            />
          )}
          {step === paymentSteps.Processing && (
            <div className="cp-pb-24">
              <CpLoader />
            </div>
          )}
          {step === paymentSteps.Receipt && (
            <PaymentConfirmation
              clientName={clientName}
              paymentTotal={paymentTotal}
              paidInvoices={getPaidInvoices()}
              paymentNumber={payment.payment_number}
              paymentCreditNumber={
                hasAdyen && !['cash', 'check', 'other'].includes(paymentDetails.method)
                  ? payment.credit_number
                  : payment.payment_credit?.credit_number
              }
              paymentMethod={paymentDetails.method}
              paymentNickname={
                hasAdyen && adyenPaymentDetails?.paymentMethod?.nickname
                  ? `${adyenPaymentDetails?.paymentMethod?.nickname} *${adyenPaymentDetails?.paymentMethod?.last_four}`
                  : paymentDetails.methodNickname
              }
              cardType={paymentDetails.ccInfo.cardType}
              refNumber={paymentDetails.refNumber}
              paymentDate={DateTime.fromISO(payment.date).toLocaleString(DateTime.DATE_SHORT)}
              isEdit={props.edit}
              isFuturePayment={isFuturePayment}
              recurrence={selectedRecurrence}
              recurringPaymentDate={recurringPaymentDate}
            />
          )}
          {step === paymentSteps.Error && (
            <div className="cp-ml-32 cps-body">
              <p className="cp-ml-12">{paymentError.subject}</p>
              <p className="cp-ml-32 cps-color-warning">{paymentError.details}</p>
            </div>
          )}
        </div>
        {step === paymentSteps.SelectInvoices && (
          <div className="cp-pt-16">
            <AccountPaymentCard
              accountPaymentDetails={accountPaymentDetails}
              onChange={accountPaymentDetailsChanged}
              readOnly={hasAppliedCredits}
              syncPaymentsToIntegration={syncPaymentsToIntegration}
              qboIntegrationInfo={qboIntegrationInfo}
            />
            <div className="cp-flex justify-end">
              <TotalPaymentCard paymentTotal={paymentTotal} remainingTotal={remainingTotal} />
            </div>
          </div>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        {paymentStepConfig[step].prevButton && (
          <CpButton btnType="secondary" className="cp-mr-16" onClick={prevStep}>
            {paymentStepConfig[step].prevButton()}
          </CpButton>
        )}
        {paymentStepConfig[step].nextButton && (
          <CpButton
            btnType="primary"
            onClick={nextStep}
            disabled={
              !paymentStepConfig[step].validate({
                clientId,
                total: getTotalPayment(),
                method: paymentDetails.method,
                paymentDate: payment.date,
                ccInfo: paymentDetails.ccInfo,
                achInfo: paymentDetails.achInfo,
                selectedRecurrence: selectedRecurrence,
                paysafe: paysafeInstance,
                ...(hasAdyen && {
                  adyenInstance: adyenPaymentDetails.adyenInstance,
                  savedMethod: adyenPaymentDetails.savedMethod,
                  savedMethodName: adyenPaymentDetails.paymentInfo.nickname,
                  paymentMethod: adyenPaymentDetails.paymentMethod,
                  hasAdyen: hasAdyen,
                }),
              })
            }>
            {paymentStepConfig[step].nextButton(isFuturePayment || paymentType === paymentTypes.recurring)}
          </CpButton>
        )}
        {step === paymentSteps.Receipt ? (
          <>
            {!isClient && !props.edit && (
              <CpButton btnType="flat" className="cp-ml-8" onClick={resetPayment}>
                Make another payment
              </CpButton>
            )}
            {!isFuturePayment && paymentType.id !== paymentTypes.recurring && (
              <>
                <CpButton
                  aria-label="Print"
                  className="cps-pull-right cps-color-monsoon cp-mr-8"
                  icon="misc-printer"
                  onClick={() => printPayment(payment.id)}
                />
                <CpButton
                  aria-label="Download"
                  className="cps-pull-right cps-color-monsoon cp-mr-8"
                  icon="af-open-down"
                  onClick={() => downloadPayment(payment.id)}
                />
              </>
            )}
          </>
        ) : (
          step !== paymentSteps.Processing && (
            <CpButton btnType="flat" className="cp-ml-8" onClick={props.onModalClose}>
              Cancel
            </CpButton>
          )
        )}
        {allowDelete && props.edit && step <= paymentSteps.ConfirmDetails && (
          <CpButton
            aria-label="Delete"
            className="cps-pull-right cps-color-monsoon cp-mr-8"
            icon="crud-trash-large"
            onClick={() => {
              if (paymentType.id === paymentTypes.oneTime) {
                const deleteMethod = payment.isScheduledPayment ? deleteScheduledPayment : deletePayment;
                deleteMethod(payment.id).subscribe(() => {
                  successToast('The payment has been deleted');
                  window.dispatchEvent(new CustomEvent('billing-ui::payment-complete'));
                  props.onModalClose('deleted');
                }, handleError);
              } else {
                removeRecurringPayment(selectedRecurrence.id).subscribe(() => {
                  successToast('The recurring payment has been removed');
                  window.dispatchEvent(new CustomEvent('billing-ui::payment-complete'));
                  props.onModalClose('deleted');
                }, handleError);
              }
            }}
          />
        )}
      </CpModal.Footer>
    </CpModal>
  );
};

export default AddPayment;

export function showPaymentModal(props) {
  return setupPaysafe().then(() => {
    return new Promise(resolve => {
      const div = document.createElement('div');
      document.body.appendChild(div);

      const root = createRoot(div);

      root.render(
        <AddPayment
          {...props}
          onModalClose={result => {
            root.unmount();
            div.parentNode.removeChild(div);
            resolve();
            props.onModalClose(result);
          }}
        />,
        div
      );
    });
  });
}

const parcelLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ErrorBoundary({ featureName: 'billing-add-payment', noStrictMode: true })(AddPayment),
});

export const fetchCreatePaymentParcel = {
  bootstrap: [setupPaysafe, parcelLifecycles.bootstrap],
  mount: [parcelLifecycles.mount],
  unmount: [parcelLifecycles.unmount],
  unload: [parcelLifecycles.unload],
};
