import React, { useState, useEffect, useContext } from 'react';
import { useHasAccess } from 'cp-client-auth!sofe';
import { BillingNavigation } from './billing-navigation.js';
import { find, isNull } from 'lodash';
import { handleError } from 'src/common/handle-error.helper.js';
import { getAllPayments } from 'src/resources/payments.resources';
import { CanopyPaymentsContext } from 'src/common/canopy-payments-context';
import { SecondaryNavigationContext } from 'src/navigation/secondary-navigation-context';
import { clientTabKeys, getPaymentsTab, getTimeTab, subTabKeys } from './client-billing-navigation.helper.js';
import { of } from 'rxjs';

export default function ClientBillingNavigation(props) {
  const clientMenuHeight = 118;
  const { hasCanopyPayments, teamCanKeyInCards, hasAdyen } = useContext(CanopyPaymentsContext);
  const secondaryNavContext = useContext(SecondaryNavigationContext);
  const clientId = props.match.params.clientId;
  const [loaded, setLoaded] = useState(false);
  const [paymentsMeta, setPaymentsMeta] = useState();
  const userHasInvoices = useHasAccess('billing_invoices');
  const allowProductivityDashboard = useHasAccess('time_productivity_dashboard');
  const userHasTasksOrNotices = useHasAccess(['tasks_generic', 'tasks_notices']);
  const allowCreditMenuItem = useHasAccess('billing_credits');
  const allowStatements = useHasAccess('billing_statements');
  const allowPayments = useHasAccess('billing_payments');
  const hasDashboard = useHasAccess('billing_dashboard');
  const canViewExpenses = useHasAccess('expense_edit');
  const permissions = [
    userHasInvoices,
    allowProductivityDashboard,
    userHasTasksOrNotices,
    allowCreditMenuItem,
    allowStatements,
    allowPayments,
    hasDashboard,
    canViewExpenses,
  ];

  const getActiveTab = () => {
    return find(clientTabKeys, tab => {
      return props.location.pathname.includes(tab);
    });
  };

  const getActiveSubTab = () => {
    return find(subTabKeys, tab => {
      return props.location.pathname.includes(tab);
    });
  };

  const handleClick = (tab, subTab) => {
    setSelectedTab(tab);
    if (clientTabs[tab].subTabs && Object.keys(clientTabs[tab].subTabs) && !subTab) {
      subTab = Object.values(subTabKeys).filter(k => k.includes(tab))[0];
    }
    setSelectedSubTab(subTab);
  };

  useEffect(() => {
    const obs = allowPayments ? getAllPayments({}, clientId, 1) : of({});
    const disposable = obs.subscribe(response => {
      setPaymentsMeta(response.meta || {});
      setLoaded(true);
    }, handleError);
    return () => disposable.unsubscribe();
  }, [clientId, allowPayments]);

  useEffect(() => {
    if (
      // before setting the side nav ensure
      isNull(hasCanopyPayments) || // canopy payments is known (async hook)
      !paymentsMeta // payments meta has fetched (local useEffect)
    ) {
      return;
    }
    setClientTabs(() => {
      if (getActiveTab() === 'time') return getTimeTab(allowProductivityDashboard, userHasTasksOrNotices);
      return {
        ...(hasDashboard && { [clientTabKeys.dashboard]: { label: 'Dashboard', icon: 'organize-tiles' } }),
        ...(userHasInvoices && { [clientTabKeys.invoices]: { label: 'Invoices', icon: 'billing-invoice' } }),
        ...(allowPayments && getPaymentsTab(hasCanopyPayments, paymentsMeta, hasAdyen, teamCanKeyInCards)),
        ...(allowCreditMenuItem && { [clientTabKeys.credits]: { label: 'Credits', icon: 'billing-circle-open-dash' } }),
        ...(canViewExpenses && { [clientTabKeys.expenses]: { label: 'Expenses', icon: 'billing-circle-open' } }),
        ...(allowStatements && { [clientTabKeys.statements]: { label: 'Statements', icon: 'file-statement' } }),
        ...(hasCanopyPayments &&
          teamCanKeyInCards &&
          hasAdyen && {
            [clientTabKeys.paymentSettings]: { label: 'Payment Settings', icon: 'billing-credit-card' },
          }),
        ...(hasCanopyPayments &&
          !hasAdyen && {
            [clientTabKeys.paymentSettings]: { label: 'Payment Settings', icon: 'billing-credit-card' },
          }),
      };
    });
  }, [...permissions, hasCanopyPayments, teamCanKeyInCards, hasAdyen, paymentsMeta]);

  const [clientTabs, setClientTabs] = useState();

  const [selectedTab, setSelectedTab] = useState(getActiveTab());
  const [selectedSubTab, setSelectedSubTab] = useState(getActiveSubTab());

  useEffect(() => {
    if (!selectedTab) return;
    if (selectedTab === 'time') {
      window.location = `/#/client/${clientId}/${selectedSubTab ? selectedSubTab : selectedTab}`;
    } else {
      window.location = `/#/client/${clientId}/billing/${selectedSubTab ? selectedSubTab : selectedTab}`;
    }
    secondaryNavContext.hideSecondaryNav();
    return () => {
      secondaryNavContext.showSecondaryNav();
    };
  }, [selectedTab, selectedSubTab]);

  useEffect(() => {
    const activeTab = getActiveTab();
    if (activeTab !== selectedTab) {
      setSelectedTab(activeTab);
    }
    setSelectedSubTab(getActiveSubTab());
  }, [props.location]);

  return (
    <BillingNavigation
      loaded={loaded}
      selectedTab={selectedTab}
      selectedSubTab={selectedSubTab}
      handleClick={handleClick}
      tabs={clientTabs}
      marginTop={clientMenuHeight}
    />
  );
}
